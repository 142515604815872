import InputText, { InputTextProps } from '../input/InputText';
import { useValibotFieldSingle } from '~/components/valibot/ValibotForm';

export default function ValibotFieldInput({
  name,
  ...props
}: Omit<InputTextProps, 'onChange' | 'value'> & { name: string }) {
  const [prefixed, value, setValue] = useValibotFieldSingle(name);

  return <InputText {...props} name={prefixed} value={value ?? ''} onChange={(value) => setValue(value || '')} />;
}
